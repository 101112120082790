<template>
    <div>
      <CDataTable
        :hover="hover"
        :striped="true"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :dark="dark"
        size="sm"
      >
        <template #activity="{item}">
            <td>
                <span><i style="color:#E0681E;">{{item.user.name}}</i> {{item.activity}}</span>
            </td>
        </template>
        <template #date="{item}">
            <td>
                <span>{{$root.momentFormatDateTime(item.date)}}</span>
            </td>
        </template>
      </CDataTable>
    </div>
</template>

<script>

export default {
  name: 'Table',
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return [
            {key: 'activity'},
            {key: 'date', _style:'width:20%'}
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
}
</script>
