<template>
  <CCard>
    <CCardHeader><strong>Activity Logs</strong></CCardHeader>
    <CCardBody>
        <ActivityLogTable :items="$store.state.activity_log.activity_log"/>
    </CCardBody>
  </CCard>
</template>

<script>
import ActivityLogTable from "./ActivityLogTable"
export default {
  name: 'ActivityLog',
  components: {
    ActivityLogTable
  },
  created(){
      this.$store.dispatch('activity_log/fetchActivityLogs');
  }
}
</script>
